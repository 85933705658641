@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.rental {
    width: 100%;
    margin-bottom: 40px;

    @include md() {
        padding: 0;
        margin: 0;
    }

    .functional-group {
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;

        .btn {
            margin: 0 8px 0 0;
        }
    }
}


.rental-detail {
    .btn-refund {
        margin: 0;
    }

    .form-group-section {
        margin: 0;

        .control-group {

            &:last-child {
                margin: 0;
            }

            &.view-mode {
                align-items: center;
            }
        }
    }

    .modal-box {
        overflow: visible;
    }

    .refresh-section {
        display: flex;
        justify-content: flex-end;

        .last-updated {
            margin: auto 10px auto auto;
        }
    }

    .view-control {
        &:empty::before {
            content: "--";
        }
    }

    .collasible-panel {
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        -webkit-transform: translateY(7px);
        transform: translateY(50%);
        overflow: hidden;
        z-index: 9997;

        .card {
            margin: 0;
            padding: 0;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            padding: 1.25rem 0;

            .filter-option {
                padding: 0.5rem 1.25rem;
                margin: 0;

                &:hover {
                    background-color: rgba(0, 215, 255, .1);
                }
            }
        }
    }

    .form-container {
        text-align: left;
    }

    .cards {
        border-radius: 4px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 6px;

        .card {
            border: 0;
        }

        .column {
            width: calc(50% - 5px);
        }
    }

    .day-pass-sec {

        .update-over-data {
            .caption {
                text-transform: none;
                @include font-family-default();
                width: auto;
                &::after {
                    content: '';
                }
            }
        }

        .over-pass {
            .control-wrapper {
                color: $danger;
            }
        }
    }
    .partial-refund {
        display: flex;
        align-items: baseline;
        &-text {
            margin-right: 16px;
        }
        .caption {
            display: none;
        }
        .form-control {
            min-width: 60px;
        }
    }
    .refund-modal {
        .error-message {
            display: none;
        }
    }
    .group-selection {
        flex-direction: column;
        gap: 16px;
        margin-top: 0;
    }
    .result-input-total {
        align-items: center!important;
    }
    .refund-type {
        .caption {
            transform: translateY(0);
        }
    }
    .refund-result {
        padding: 30px;
        .result-input {
            align-items: center;
            margin-bottom: 12px;
        }
        .result-refund {
            color: $danger
        }
    }
    .original-amount {
        align-items: center!important;
    }
}
