@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";


.reissue-invoice-btn {
    margin: 0 12px;
}

.reissue-invoice-modal {
    .modal-title {
        z-index: 1;
    }
    .modal-box {
        max-width: 80vw;
        @include md() {
            height: 100%;
        }
    }

    .modal-body {

        height: 484px;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }
    .reissue-invoice-form {
        width: 100%;
        border-bottom: 1px solid #f1f3f3;
        height: fit-content;
        padding-bottom: 24px;
        margin-bottom: 24px;
        .caption {
            &.required:after {
                content: '*';
            }
        }

        .reissue-invoice-type {
            .inline-wrapper {
                .group-selection {
                    &>div {
                        width: 100%;
                        margin-right: auto;
                    }
                }
            }
        }

        .button-group {
            width: 100%;
        }
    }
    .invoice-list-section {
        width: 100%;
        flex-direction: column;

        .title {
            @include font-family-bold;
            height: 24px;
            color: $bluish-grey;
            font-size: 18px;
            line-height: 1.33;
            margin-bottom: 12px;
            text-align: left;
            width: 100%;
        }
        .invoice-list {
            list-style-type: none;
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;

            .invoice-item {
                width: 100%;
                text-align: left;
                display: flex;
                font-size: 14px;
                gap: 16px;
                margin-bottom: 8px;

            }
            .invoice-date {
                font-weight: bolder;
                min-width: 110px;
            }
        }
    }
}
